import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../../layouts'

import ResultsSidenav from "../../partials/results-sidenav"

import '../../scss/list.scss'

import demos from "../../../cristin/demos.yaml"

const DemosPage = () => {
  return (
    <Layout>
      <div className="list-page results">

      <Helmet>
        <title>DigiWells – demonstrations and software</title>
        <meta property="og:title" content="DigiWells – demonstrations and software" />
        <meta name="description" content="DigiWells demonstrations and software." />
        <meta property="og:description" content="DigiWells demonstrations and software." />
        {
          /*
          <meta name="image" content={image} />
          <meta property="og:image" content={image} />
          */
        }
      </Helmet>

        <div className="container">
          <ResultsSidenav />

          <div className="container-7">
            <h1 className="page-title">Demonstrations and Software</h1>

            <div className="page-intro"> </div>

            <div className="list">
              {
                demos.results.map(function(publication, index) {

                  // const photo = require('../../assets/images/' + publication.image);
                  return (
                    <a className="list-item" href={publication.link.url} target="_blank" rel="noreferrer" key={index}>
                      <div className="list-item-text">
                        <h3 className=" list-item-title small">
                          {publication.title}
                          <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24px"  viewBox="0 -2 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                        </h3>
                        <div className="list-item-description publication">
                          {publication.description}
                        </div>
                        <div className="list-item-description details">​​
                          <span className="authors">
                            {publication.authors}
                          </span>
                          <br/>
                          <span>
                            {publication.link.display}, {publication.year}
                            </span>
                        </div>
                      </div>

                    </a>
                  )
                })
              }
            </div>
            <p>Total: {demos.results.length}</p>
            {/* <p>Last updated: {demos.time ? demos.time : 'recently'}</p> */}
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default DemosPage
